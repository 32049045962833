<template>
    <span :class=classesFinal >Notifya</span> 
</template>
<style>
</style>
<script>
export default {
    name: 'NotifyaLogo',
    data() {
        return {
            classesFinal: "lexend-font " + this.classes
        }
    },
    props: {
        "classes": String
    }
}

</script>